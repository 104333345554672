<template>
<v-app>

 
    <section class="tables">
 
      <div class="row">
        
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Affecter un planning
                   <router-link to="/marketings">
                <span class="float-right btn btn-info">
                  retour
                </span>
                </router-link>
              </div>
              <div class="container">
               
                  
   
                <form>
                  
                  <div class="row">
                       <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Nom <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          id="nom"
                          v-model="nom"
                          required
                          
                        />
                      </div>
                    </div>
                          <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Catégorie <span class="obligatoire">*</span>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="categorie"
                          required
  
                        >
                          <option
                            v-for="user in items"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                          <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Commercial <span class="obligatoire">*</span>
                        </label>
  
                       <select
                          id="inputState"
                          class="form-control"
                          v-model="commercial"
                          required
  
                        >
                          <option
                            v-for="user in items"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          >Type Journée <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          id="nom"
                          v-model="type"
                          required
                          
                        />
                      </div>
                    </div>
                    <!--  col-md-6   -->
  
                   
                  
                     <div class="col-md-6">
                      <div class="form-group">
                        <label for="last"
                          >Animatrice <span class="obligatoire">*</span></label
                        >
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="user_id"
                          required
  
                        >
                          <option
                            v-for="user in users"
                            :key="user._id"
                            v-bind:value="user._id"
                          >
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
                   
                    <!-- <div class="col-md-4">
                         <v-date-picker
                      v-model="dates"
                      multiple
                    ></v-date-picker>
                    </div>
                      <div class="col-md-8">
                        <div v-for="(date, index) in dates" :key="index">
                          <div class="row">
                            <div class="col-md-2">
                          {{date}}
                            </div>
                                <div class="col-md-6">
                                   <div class="form-group">
                       
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="user_id"
                          required
  
                        >
                          <option
                            v-for="user in users"
                            :key="user._id"
                            v-bind:value="user._id"
                          >
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                                    </div>
                                  </div>
                        </div>
                    </div> -->
                   
                    
                  </div>
                 
                  
                </form>
               
    <div class="d-flex align-items-center justify-content-center">
        <div >
             
                      <div>
                          <h5 >
                                            Date début:
                                        </h5>
                        <v-date-picker  color='#00cee9'   max-width="90"  v-model="date_debut"></v-date-picker>
                        <!-- <span class="text-danger" v-if="error != null">
                    {{ error }}
                      </span>  -->
                          
                      </div> 
                               
                 
        </div>
    </div>
      

    
     <div class="d-flex align-items-center justify-content-center">
      <div>
          <v-sheet
    class="mx-auto"
    max-width="600"
  >
    <v-slide-group
      multiple
      show-arrows
    >
      <v-slide-item
       
       
      >
        <v-btn
          class="mx-2"
          :input-value="showlundi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglelundi()"
        >
          Lundi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
        
      >
        <v-btn
          class="mx-2"
          :input-value="showmardi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglemardi()"
        >
          Mardi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
     
      >
        <v-btn
          class="mx-2"
          :input-value="showmercredi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglemercredi()"
        >
          Mercredi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
        
      >
        <v-btn
          class="mx-2"
          :input-value="showjeudi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglejeudi()"
        >
          Jeudi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
        
      >
        <v-btn
          class="mx-2"
          :input-value="showvendredi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglevendredi()"
        >
         Vendredi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
        
      >
        <v-btn
          class="mx-2"
          :input-value="showsamdi"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="togglesamdi()"
        >
          Samdi
        </v-btn>
      </v-slide-item>
       <v-slide-item
       
       
      >
        <v-btn
          class="mx-2"
          :input-value="showdimanche"
          active-class="teal accent-3 white--text"
          depressed
          rounded
          @click="toggledimanche()"
        >
          Dimanche
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
      </div>
      </div>
      <br>
      <br>

                <div class="row">
                
                   
                    
                     
                   
    
                  
                   
                    <br>
                    <br>
                      <transition name="bounce">
                         <div v-show="showlundi" class="col-md-12 ">
                    
                        <div >
                          <div class="row ">
                                <div class="col-md-2">
                                  <div class="row">
                                <div   v-if="!validelundi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                      <span style="height:35px;width:100px;font-size:15px"  class="badge badge-info">Lundi--></span>
                                    </div>
                                      </div>
                 
                                     
                               </div>
                                   <div class="col-md-1">
                                    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filtrer
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societe"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marque"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
           
          <span v-show="errorlundi" style="color:red">error</span>
           
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduit"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div>   
                          <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select  :disabled="validelundi"   class="lundi" label="reference"   multiple v-model="lundi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validelundi"  label="name" v-model="lundi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                            <div class="col-md-2">
                                <v-btn
           v-if="!validelundi"
      
      color="primary"
      class="mr-4"
      @click="lundiadd"
      :loading="loading"
      :disabled="loading"
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validelundi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
       color="orange darken-2"
      class="mr-4"
      @click="lundidelete"
     
    >
        <i 
                        class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white" 
                        
                      />
    </v-btn>                 
                           </div>

                                
                                  </div>
                        </div>
                   
                      <!-- <div class="form-group">
                         <div class="rule-output-container">
                                         <label id='rule-output' for="start_date"></label> 
                                        <h5 id="rule-output">
                                            Sélectionner la Règle:
                                        </h5>
                                    </div>
                        <ejs-recurrenceeditor :selectedType='selectedType' :change='onChange'  ref='EditorObj'></ejs-recurrenceeditor>
                      </div> -->
                 
                    </div> 
                          </transition>
                           <transition name="bounce">
                            <div v-show="showmardi" class="col-md-12">
                    
                        <div >
                          <div class="row ">
                                <div class="col-md-2">
                                   <div class="row">
                                <div   v-if="!validemardi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                      <span style="height:35px;width: 100px;font-size:15px"  class="badge badge-info">Mardi --></span>
                                    </div>
                                      </div>

                                     
                                     
                               </div>
                                <div class="col-md-1">
                                    <v-dialog
      v-model="dialogmardi"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filtrer
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societemardi"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquemardi"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitmardi"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                           <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select :disabled="validemardi" label="reference" multiple v-model="mardi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validemardi" label="name" v-model="mardi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                         <div class="col-md-2">
                                <v-btn
           v-if="!validemardi"
      
      color="primary"
      class="mr-4"
      @click="mardiadd"
     
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validemardi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
         class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
      color="orange darken-2"
      class="mr-4"
      @click="mardidelete"
     
    >
        <i 
             class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"           
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                             </transition>
                  
                  
                       
                   <transition name="bounce">
                      <div v-show="showmercredi" class="col-md-12">
                    
                        <div >
                          <div class="row ">
                                <div class="col-md-2">
                                      <div class="row">
                                <div   v-if="!validemercredi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                      <span style="height:35px;width: 100px;font-size:15px"  class="badge badge-info">Mercredi --></span>
                                    </div>
                                      </div>
                                      
                                     
                               </div>
                                   <div class="col-md-1">
                                    <v-dialog
      v-model="dialogmercredi"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societemercredi"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquemercredi"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitmercredi"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                               
                              <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select :disabled="validemercredi" label="reference" multiple v-model="mercredi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validemercredi" label="name" v-model="mercredi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                        <div class="col-md-2">
                                <v-btn
           v-if="!validemercredi"
      
      color="primary"
      class="mr-4"
      @click="mercrediadd"
     
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validemercredi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
     color="orange darken-2"
      class="mr-4"
      @click="mercredidelete"
     
    >
        <i 
                      class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"
                        
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                       </transition>
                   <transition name="bounce">
                      <div v-show="showjeudi" class="col-md-12">
                    
                        <div >
                          <div class="row">
                                <div class="col-md-2">
                                    <div class="row">
                                <div   v-if="!validejeudi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                     <span style="height:35px;width: 100px;font-size:15px" class="badge badge-info">Jeudi --></span>
                                     
                                    </div>
                                      </div>
                                      
                               </div>
                                     <div class="col-md-1">
                                    <v-dialog
      v-model="dialogjeudi"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societejeudi"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquejeudi"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitjeudi"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                               <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select :disabled="validejeudi" label="reference" multiple v-model="jeudi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validejeudi" label="name" v-model="jeudi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                        <div class="col-md-2">
                                <v-btn
           v-if="!validejeudi"
      
      color="primary"
      class="mr-4"
      @click="jeudiadd"
     
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validejeudi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
       color="orange darken-2"
      class="mr-4"
      @click="jeudidelete"
     
    >
        <i 
                          class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"
                        
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                       </transition>
                   <transition name="bounce">
                      <div v-show="showvendredi" class="col-md-12">
                    
                        <div >
                          <div class="row">
                                <div class="col-md-2">
                                    <div class="row">
                                <div   v-if="!validevendredi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                     <span style="height:35px;width: 100px;font-size:15px"  class="badge badge-info">Vendredi --></span>
                                     
                                    </div>
                                      </div>
                                      
                                     
                               </div>
                                     <div class="col-md-1">
                                    <v-dialog
      v-model="dialogvendredi"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societevendredi"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquevendredi"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitvendredi"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                             <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select :disabled="validevendredi" label="reference" multiple v-model="vendredi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validevendredi" label="name" v-model="vendredi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                          <div class="col-md-2">
                                <v-btn
           v-if="!validevendredi"
      
      color="primary"
      class="mr-4"
      @click="vendrediadd"
    
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validevendredi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
    color="orange darken-2"
      class="mr-4"
      @click="vendredidelete"
     
    >
        <i 
                         class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"
                        
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                       </transition>
                   <transition name="bounce">
                      <div v-show="showsamdi" class="col-md-12">
                    
                        <div >
                          <div class="row ">
                                <div class="col-md-2">
                                   <div class="row">
                                <div   v-if="!validesamdi" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                     <span style="height:35px;width: 100px;font-size:15px"  class="badge badge-info">Samdi --></span>
                                     
                                    </div>
                                      </div>
                                      
                                     
                               </div>
                                     <div class="col-md-1">
                                    <v-dialog
      v-model="dialogsamdi"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societesamdi"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquesamdi"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitsamdi"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                              <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select :disabled="validesamdi" label="reference" multiple v-model="samdi.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select :disabled="validesamdi" label="name" v-model="samdi.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                            <div class="col-md-2">
                                <v-btn
           v-if="!validesamdi"
      
      color="primary"
      class="mr-4"
      @click="samdiadd"
      
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validesamdi"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
       color="orange darken-2"
      class="mr-4"
      @click="samdidelete"
     
    >
        <i 
                          class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"
                        
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                       </transition>
                   <transition name="bounce">
                      <div v-show="showdimanche" class="col-md-12">
                    
                        <div >
                          <div class="row ">
                                <div class="col-md-2">
                                    <div class="row">
                                <div   v-if="!validedimanche" class="col-md-4">
                                                  <v-avatar

                                                   size="33">
                                                    
      <img
        :src="require('@/images/test.gif')"
        alt="John"
      >
    </v-avatar> 
                                   </div>
                                <div class="col-md-8">
                                     <span style="height:35px;width:100px;font-size:15px"  class="badge badge-info">Dimanche --></span>
                                     
                                    </div>
                                      </div>
                                      
                                     
                               </div>
                                     <div class="col-md-1">
                                    <v-dialog
      v-model="dialogdimanche"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="light-blue lighten-2 white--text">
          Filter les Produits
        </v-card-title>

        <v-card-text>
          <br>
          <div class="row">
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          ><strong>Societe</strong> 
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societedimanche"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
             <div class="col-md-6">
                      <div class="form-group">
                        <label for="first"
                          > <strong>Marque</strong>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marquedimanche"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                   
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
             color="green accent-4"
              dark
            @click="filtrerproduitdimanche"
          >
            Filtrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
                                    </div> 
                               <div class="col-md-3">
                                
                         <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                                   <h6 style="color:white;font-size: 16px;">Produits</h6>
                                  <v-select label="reference" multiple v-model="dimanche.produit"  :options="produits"></v-select> 
                                </div>
                            </div>
                               <div class="col-md-4">
                                
                           <div style="background: #00cee9; padding: 0.2rem; border-radius: 0.3rem">
                             <h6 style="color:white;font-size: 16px;">Clients</h6>
                                  <v-select label="name" v-model="dimanche.client"  :options="clients"></v-select> 
                                </div>
  
                            </div>
                           <div class="col-md-2">
                                <v-btn
           v-if="!validedimanche"
      
      color="primary"
      class="mr-4"
      @click="dimancheadd"
 
    >
      <i 
         class="mdi mdi-send" style="font-size:25px" 
                        
                      />
    </v-btn> 
      <v-btn
      v-if="validedimanche"
      class="mx-2"
      fab
      dark
     
      color="success"
    >
       <i 
                        class="mdi mdi-check" style="font-size:30px" 
                        
                      />
    </v-btn>
       <v-btn
      
      color="orange darken-2"
      class="mr-4"
      @click="dimanchedelete"
     
    >
        <i 
                        class="mdi mdi-border-color" style="font-size:30px;margin-top:5px;color:white"
                        
                      />
    </v-btn>                 
                           </div>


                                
                                  </div>
                        </div>
                   
                  
                 
                    </div> 
                     </transition>
                     <br>
                    
                  
                </div>
                <button   @click="addmarketing()" class="btn btn-primary" style="flot:right;">
                   + Modifier le planning
                  </button>
                <br>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</v-app>
 
</template>
<script>
import Vue from 'vue';
  import { RecurrenceEditorPlugin } from '@syncfusion/ej2-vue-schedule';

  Vue.use(RecurrenceEditorPlugin);
  import GridMultiSelect from 'vue-gridmultiselect';
import 'vue-gridmultiselect/dist/vue-gridmultiselect.css';

  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  import moment from 'moment';
 export default {
  components: { GridMultiSelect },
    data() {
     let d = new Date();
     let ddebut=(new Date(d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7)))).toISOString().substr(0, 10);
      return {
       errorlundi:false,
       marque: "",
      societe: "",
        marquemardi: "",
      societemardi: "",
        marquemercredi: "",
      societemercredi: "",
        marquejeudi: "",
      societejeudi: "",
        marquevendredi: "",
      societevendredi: "",
        marquesamdi: "",
      societesamdi: "",
        marquedimanche: "",
      societedimanche: "",
              dialog: false,
              dialogmardi:false,
               dialogmercredi:false,
                dialogjeudi:false,
                 dialogvendredi:false,
                  dialogsamdi:false,
                   dialogdimanche:false,
              validelundi:false,
              validemardi:false,
              validemercredi:false,
              validejeudi:false,
              validevendredi:false,
              validesamdi:false,
              validedimanche:false,
             loader: null,
            loading: false,
           selectedItems: null,
          /////////events jours
          showlundi:false,
           showmardi:false,
            showmercredi:false,
             showjeudi:false,
              showvendredi:false,
               showsamdi:false,
                showdimanche:false,



          ////////////
          events:[],
          lundi: {
        date: "",
        jour:'lundi',
        client: "",
        produit: "",
       
      },
         mardi: {
        date:'',
        jour:'mardi',
        client: "",
        produit: "",
       
      },
         mercredi: {
        date:'',
        jour:'mercredi',
        client: "",
        produit: "",
       
      },
         jeudi: {
        date:'',
        jour:'jeudi',
        client: "",
        produit: "",
       
      },
         vendredi: {
        date: '',
        jour:'vendredi',
        client: "",
        produit: "",
       
      },
         samdi: {
        date:'',
        jour:'samdi',
        client: "",
        produit: "",
       
      },
         dimanche: {
        date: '',
        jour:'dimanche',
        client: "",
        produit: "",
       
      },
        ///////////////
         marques: ['foo', 'bar', 'fizz', 'buzz'],
        societes:['PROCHIDIA','ARGANIA','ALL'],
        dates:[],
         items: ['foo', 'bar', 'fizz', 'buzz'],
      value: ['foo', 'bar', 'fizz', 'buzz'],
       options: [
      'foo',
      'bar',
      'baz'
    ],
      test:"",
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        selectedType: 1  ,
        check:[],
            selected:"",
            nom:"",
            categorie:"",
            type:"",
            commercial:"",
            dates:[],
            dates2:[],
            date_debut:"",
            // date_fin:(new Date(d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7)))).toISOString().substr(0, 10),
            client_id:[],
            user_id: "",
            produit_id: [],
           
            token : localStorage.getItem("token"),

               
        users: [],
        clients: [],
        produits: [],
         produitsoriginal: [],

        error: "",
      };
    },
    computed: {
   
},
  
    created() {
      this.getusers();
      this.getclients();
      this.getproduits();
       this.getMarketingById();
        

    },
    watch: {
    
      date_debut: function(val) {
          //do something when the data changes.
          if (val) {
              var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var dayName = days[new Date(this.date_debut).getDay()];
            if(dayName!=='Mon')
            {
                    {Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Il faut que le jour de début lundi!',
              footer: ''
            
            })}
              let d = new Date();
              let ddebut=(new Date(d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7)))).toISOString().substr(0, 10);
              this.date_debut=ddebut;
            }
          }
      }
  },
    mounted: function () {
          
        $(document).ready(function() {
            $("button").click(function() {
                $(document).scrollTop($(document).height());
            });
             $(document).scrollTop($(document).height());
        });
        $("#js-licensing").hide();
   
    let outputElement = document.querySelector('#rule-output');
    // outputElement.innerText = 'Sélectionner la Règle:';
},
    methods: {
        
      getMarketingById(){
           
           HTTP.get('marketings/getmarketingbyid/'+this.$route.params.id)
        .then(res => {
        

           this.nom=res.data.nom
          this.type=res.data.type
        //   this.events=res.data.events
           this.categorie=res.data.categorie
           this.commercial=res.data.commercial
          this.date_debut=res.data.date_debut.substr(0, 10)
          this.user_id=res.data.user_id
           res.data.eventscalander.forEach((event, index) => {
            this.check.push(event.jour)
            if(event.jour=="lundi")
            {     
                this.validelundi=true;
                this.lundi.client=event.client,
                this.lundi.produit=event.Produitsall,
                this.lundi.date=this.date_debut,
                this.lundi.jour="lundi"
                this.showlundi=true,
                this.events.push(this.lundi)
            }
               else if(event.jour=="mardi")
            {     
                this.validemardi=true;
                 this.mardi.client=event.client
                this.mardi.produit=event.Produitsall
                 let date = new Date(this.date_debut);
      this.mardi.date=new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10)
                this.mardi.jour="mardi"
                this.showmardi=true,
                this.events.push(this.mardi)
            }
              else if(event.jour=="mercredi")
            {     
                  this.validemercredi=true;
                  this.mercredi.client=event.client
                  this.mercredi.produit=event.Produitsall
                const date = new Date(this.date_debut);
                this.mercredi.date=new Date(date.setDate(date.getDate() + 2)).toISOString().substr(0, 10)
                this.mercredi.jour="mercredi"
                this.showmercredi=true,
                this.events.push(this.mercredi)
            }
              else if(event.jour=="jeudi")
            {     
                this.validejeudi=true;
                this.jeudi.client=event.client
                this.jeudi.produit=event.Produitsall
                const date = new Date(this.date_debut);
      this.jeudi.date=new Date(date.setDate(date.getDate() + 3)).toISOString().substr(0, 10)
                this.jeudi.jour="jeudi"
                this.showjeudi=true,
                this.events.push(this.jeudi)
            }
              else if(event.jour=="vendredi")
            {     
                this.validevendredi=true;
                this.vendredi.client=event.client
                this.vendredi.produit=event.Produitsall
                 const date = new Date(this.date_debut);
      this.vendredi.date=new Date(date.setDate(date.getDate() + 4)).toISOString().substr(0, 10)
                this.vendredi.jour="vendredi"
                this.showvendredi=true,
                this.events.push(this.vendredi)
            }
              else if(event.jour=="samdi")
            {     
                this.validesamdi=true;
                this.samdi.client=event.client
                this.samdi.produit=event.Produitsall
                 const date = new Date(this.date_debut);
      this.samdi.date=new Date(date.setDate(date.getDate() + 5)).toISOString().substr(0, 10)
                this.samdi.jour="samdi"
                this.showsamdi=true,
                this.events.push(this.samdi)
            }
              else if(event.jour=="dimanche")
            {     
                this.validedimanche=true;
                this.dimanche.client=event.client
                this.dimanche.produit=event.Produitsall
                 const date = new Date(this.date_debut);
      this.dimanche.date=new Date(date.setDate(date.getDate() + 6)).toISOString().substr(0, 10)
                this.dimanche.jour="dimanche"
                this.showdimanche=true,
                this.events.push(this.dimanche)
            }
           
           })
            


       }, err => {
          this.error = err.response.data.error
       })
       },

      test(){
         let recObject = this.$refs.EditorObj;
            //let dates = recObject.getRecurrenceDates(this.selected);
            this.dates = recObject.getRecurrenceDates(
                new Date(this. date_debut),
                this.selected
            );
                 this.dates.forEach((element, index, array) => {
                this.dates2.push(new Date(element).toString().slice(0, 15));
                
            });
           

      },
        onChange: function(args) {
        let outputElement = document.querySelector('#rule-output');
        if(args.value == "") {
            outputElement.innerText = ' Sélectionner la Règle:';
        } else {
             //outputElement.innerText = args.value;
            this.selected= args.value;
            
        }
    },
      getusers() {
        HTTP
          .get("annonces/getanimatrice")
          .then((response) => {
            
            this.users = response.data;
          })
          .then((err) => console.log(err));
      },

      getclients() {
        HTTP
          .get("annonces/getclientss")
          .then((response) => {
           
            this.clients = response.data;
          })
          .then((err) => console.log(err));
      },

      getproduits() {
        HTTP
          .get("marketings/getproduitss")
          .then((response) => {
           
            this.produits = response.data;
            this.produitsoriginal=response.data;
          })
          .then((err) => console.log(err));
      },
  
      addmarketing()
      
      {  
         
        
          //  let recObject = this.$refs.EditorObj;
           
          //   this.dates = recObject.getRecurrenceDates(
          //       new Date(this. date_debut),
          //       this.selected
          //   );
          //        this.dates.forEach((element, index, array) => {
          //       this.dates2.push(new Date(element).toString().slice(0, 15));
                
          //   });
                     if(this.nom==''||this.type=='' ||this.categorie==''||this.commercial==''||this.date_debut==''||this.user_id.length==0||this.events.length==0
                      ||(this.showlundi==true && this.validelundi==false)||(this.showmardi==true &&this.validemardi==false)
                      ||(this.showmercredi==true &&this.validemercredi==false)||(this.showjeudi==true &&this.validejeudi==false)
                      ||(this.showvendredi==true &&this.validevendredi==false)||(this.showsamdi==true &&this.validesamdi==false)
                      ||(this.showdimanche==true &&this.validedimanche==false))
                {
                   let msgnom='';
                    let msgtype='';
                     let msgcategorie='';
                    let msgcommercial=''; 
                    let msguser='';
                    let msgevents='';let msglundi='';let msgmardi='';let msgmercredi='';let msgjeudi='';let msgvendredi='';
                    let msgsamdi=''; let msgdimanche='';  
                  if(this.nom==''){msgnom=' *Le champ nom  est requis'}
                   if(this.type==''){msgtype=' *Le champ type  est requis'}
                    if(this.categorie==''){msgcategorie=' *Le champ categorie  est requis'}
                      if(this.commercial==''){msgcommercial=' *Le champ commercial est requis'}
                   if(this.user_id.length==0){msguser=' *Le champ Animatrice  est requis'}
                   if(this.events.length==0){ msgevents=' *il faut sélectionner les jours'}
                     if(this.showlundi==true && this.validelundi==false){msglundi=' *Il faut valider lundi'}
                      if(this.showmardi==true &&this.validemardi==false){msgmardi=' *Il faut valider mardi'}
                       if(this.showmercredi==true &&this.validemercredi==false){msgmercredi=' *Il faut valider Mercredi'}
                        if(this.showjeudi==true &&this.validejeudi==false){msgjeudi=' *Il faut valider jeudi'}
                         if(this.showvendredi==true &&this.validevendredi==false){msgvendredi=' *Il faut valider vendredii'}
                          if(this.showsamdi==true &&this.validesamdi==false){msgsamdi=' *Il faut valider samdi'}
                           if(this.showdimanche==true &&this.validedimanche==false){msgdimanche=' *Il faut valider dimanche'}
                          
                  
                  
           Swal.fire({
          icon: 'error',
            // title: 'Oops...',
            html:  msgnom +'<br>'+msgtype+'<br>'+msgcategorie+'<br>'+msgcommercial+'<br>'+msguser+'<br>'+msgevents+'<br>'+msglundi+'<br>'+msgmardi
            +'<br>'+msgmercredi+'<br>'+msgjeudi+'<br>'+msgvendredi+'<br>'+msgsamdi+'<br>'+msgdimanche,
            footer: ''
          
          })}
          else{
             
        let planning = {
          selected:this.selected,
          nom: this.nom,
          type: this.type,
          events:this.events,
          categorie: this.categorie,
          commercial: this.commercial,
          date_debut: this.date_debut,
          date_fin: this.date_debut,
          user_id: this.user_id,
          produit_id: this.produit_id,
          client_id: this.client_id,
          dates:this.dates2,
          token : localStorage.getItem("token"),
          id:this.$route.params.id

        };

          HTTP.post("marketings/updatemarketing", planning).then(
        (res) => {
          if (res.status === 200) {
            Toast.fire({
              icon: "success",
              title: "marketing updated",
            });
            this.$router.push("/marketings");
          }
        },
        (err) => {
          this.error = err.response.data;
        }
      );
          }
       

   
    },


    getuser() {
      var token = localStorage.getItem("token");
      HTTP.get("user/getcurrentuser" + token)
        .then((response) => {
          this.id = response.data;
        })
        .then((err) => console.log(err));
    },
    lundiadd(){

      if(this.lundi.produit==''||this.lundi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
      else{
         Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour  lundi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
           let pass= this.check.includes("lundi");
            let checklundi={
                  date:this.date_debut,
                  client:this.lundi.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
                       this.loader='loading';
         const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
        this.lundi.date=this.date_debut;
      this.events.push(this.lundi);
      this.validelundi=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "lundi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );

       

          }
        });


        //////
       
    
      }
          

    },
    lundidelete(){
    
      
      this.events = this.events.filter(x => x.jour !== 'lundi');
      let select=$('.lundi');
      // console.log(select)
      // $('.lundi').removeAttr('disabled');
      // this.lundi.client='';
      // this.lundi.date='';
      // this.societe="";
      // this.marque='';
      // this.lundi.produit='';
      this.validelundi=false;
      this.produits=this.produitsoriginal;
      
    },
     mardiadd(){
            if(this.mardi.produit==''||this.mardi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
else{
     Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour  Mardi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

         const date = new Date(this.date_debut);
      this.mardi.date=new Date(date.setDate(date.getDate() + 1)).toISOString().substr(0, 10)
      let pass= this.check.includes("mardi");
            let checklundi={
                  date:this.mardi.date,
                  client:this.mardi.client.name,
                  pass:pass
            }
            
                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
         this.events.push(this.mardi);
      this.validemardi=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "Mardi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
      

          }
        });


  ///////
    
}
    },
    mardidelete(){
      
      this.events = this.events.filter(x => x.jour !== 'mardi');
      // this.mardi.client='';
      // this.mardi.date='';
      // this.mardi.produit='';
      this.validemardi=false;
      this.produits=this.produitsoriginal;
      
    },
      mercrediadd(){
              if(this.mercredi.produit==''||this.mercredi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
  else{
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour Mercredi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

          const date = new Date(this.date_debut);
      this.mercredi.date=new Date(date.setDate(date.getDate() + 2)).toISOString().substr(0, 10)
      let pass= this.check.includes("mercredi");
            let checklundi={
                  date:this.mercredi.date,
                  client:this.mercredi.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
        this.events.push(this.mercredi);
      this.validemercredi=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "mercredi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
     

          }
        });

    //////
     
  }

    },
    mercredidelete(){
      
      this.events = this.events.filter(x => x.jour !== 'mercredi');
      // this.mercredi.client='';
      // this.mercredi.date='';
      // this.mercredi.produit='';
       this.validemercredi=false;
      this.produits=this.produitsoriginal;
      
    },
      jeudiadd(){
              if(this.jeudi.produit==''||this.jeudi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
else{
      Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour  Jeudi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

       const date = new Date(this.date_debut);
      this.jeudi.date=new Date(date.setDate(date.getDate() + 3)).toISOString().substr(0, 10)
      let pass= this.check.includes("jeudi");
            let checklundi={
                  date:this.jeudi.date,
                  client:this.jeudi.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
        this.events.push(this.jeudi);
      this.validejeudi=true;
       this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "jeudi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
     

          }
        });


  //////
     
}

    },
    jeudidelete(){
      
      this.events = this.events.filter(x => x.jour !== 'jeudi');
      // this.jeudi.client='';
      // this.jeudi.date='';
      // this.jeudi.produit='';
       this.validejeudi=false;
      this.produits=this.produitsoriginal;
      
    },
       vendrediadd(){
              if(this.vendredi.produit==''||this.vendredi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
else{
      Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour  Vendredi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

      const date = new Date(this.date_debut);
      this.vendredi.date=new Date(date.setDate(date.getDate() + 4)).toISOString().substr(0, 10)
      let pass= this.check.includes("vendredi");
            let checklundi={
                  date:this.vendredi.date,
                  client:this.vendredi.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
              this.events.push(this.vendredi);
      this.validevendredi=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "vendredi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
   

          }
        });

  /////
      
}

    },
    vendredidelete(){
      
      this.events = this.events.filter(x => x.jour !== 'vendredi');
      // this.vendredi.client='';
      // this.vendredi.date='';
      // this.vendredi.produit='';
      this.validevendredi=false;
      this.produits=this.produitsoriginal;
      
    },
        samdiadd(){
                if(this.samdi.produit==''||this.samdi.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
else{
      Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour  Samdi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

      const date = new Date(this.date_debut);
      this.samdi.date=new Date(date.setDate(date.getDate() + 5)).toISOString().substr(0, 10)
      let pass= this.check.includes("samdi");
            let checklundi={
                  date:this.samdi.date,
                  client:this.samdi.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
      this.events.push(this.samdi);
      this.validesamdi=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "samdi ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
     

          }
        });

  /////
     
}

    },
    samdidelete(){
      
      this.events = this.events.filter(x => x.jour !== 'samdi');
      // this.samdi.client='';
      // this.samdi.date='';
      // this.samdi.produit='';
      this.validesamdi=false;
      this.produits=this.produitsoriginal;
      
    },
       dimancheadd(){
              if(this.dimanche.produit==''||this.dimanche.client=='')
      {Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Il faut choisir le client et le produit!',
  footer: ''
 
})}
else{
    Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "confirmer le jour Dimanche",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {

      const date = new Date(this.date_debut);
      this.dimanche.date=new Date(date.setDate(date.getDate() + 6)).toISOString().substr(0, 10)
      let pass= this.check.includes("dimanche");
            let checklundi={
                  date:this.dimanche.date,
                  client:this.dimanche.client.name,
                  pass:pass
            }
            

                  HTTP.post("marketings/checklundiupdate", checklundi).then(
       (res) => {
           if (res.status === 200) {
              if (res.data.title === "famma") {
             
                   {Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: checklundi.client+' est déjà affecté à un planning pour cette date!'+(checklundi.date),
                  footer: ''
                
                })}
             
           }
            else{
      this.events.push(this.dimanche);
      this.validedimanche=true;
      this.produits=this.produitsoriginal;
                Toast.fire({
               icon: "success",
               title: "dimanche ajouté",
             });
            }
           
             
           }
          
         },
         (err) => {
           this.error = err.response.data;
         }
       );
     

          }
        });
  //////
      
}

    },
    dimanchedelete(){
      
      this.events = this.events.filter(x => x.jour !== 'dimanche');
      // this.dimanche.client='';
      // this.dimanche.date='';
      // this.dimanche.produit='';
      this.validedimanche=false;
      this.produits=this.produitsoriginal;
      
    },
    filtrerproduit(){
      if(this.societe===""||this.marque===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
            // this.errorlundi=true;
      }

       else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
             const results = this.produits.filter(element => {
  
   return  element.marque === this.marque;
 });
   this.produits=results
   this.dialog = false

       }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societe && element.marque === this.marque;
});
  this.produits=results
   this.dialog = false

      }

  
     
    },
      filtrerproduitmardi(){
         if(this.societemardi===""||this.marquemardi===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

      else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquemardi;
});
  this.produits=results
       this.dialogmardi = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societemardi && element.marque === this.marquemardi;
});
  this.produits=results
       this.dialogmardi = false

      }

  
 
    },
          filtrerproduitmercredi(){
              if(this.societemercredi===""||this.marquemercredi===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

      else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquemercredi;
});
  this.produits=results
   this.dialogmercredi = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societemercredi && element.marque === this.marquemercredi;
});
  this.produits=results
   this.dialogmercredi = false

      }

  
     
    },
   
       filtrerproduitjeudi(){
                if(this.societejeudi===""||this.marquejeudi===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

     else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquejeudi;
});
  this.produits=results
  this.dialogjeudi = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societejeudi && element.marque === this.marquejeudi;
});
  this.produits=results
  this.dialogjeudi = false

      }

  
      
    },
       filtrerproduitvendredi(){
              if(this.societevendredi===""||this.marquevendredi===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

      else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquevendredi;
});
  this.produits=results
  this.dialogvendredi = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societevendredi && element.marque === this.marquevendredi;
});
  this.produits=results
  this.dialogvendredi = false

      }

  
      
    },
       filtrerproduitsamdi(){
         if(this.societesamdi===""||this.marquesamdi===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

    else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquesamdi;
});
  this.produits=results
  this.dialogsamdi = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societesamdi && element.marque === this.marquesamdi;
});
  this.produits=results
  this.dialogsamdi = false

      }

  
      
    },
         filtrerproduitdimanche(){
            if(this.societedimanche===""||this.marquedimanche===""){
                     {Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: 'Il faut choisir la société et la marque !',
               footer: ''
            
             })}
           
      }

      else if(this.societe==="ALL"){
            this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return  element.marque === this.marquedimanche;
});
  this.produits=results
     this.dialogdimanche = false

      }
      else{
          this.produits=this.produitsoriginal;
            const results = this.produits.filter(element => {
  
  return element.societe === this.societedimanche && element.marque === this.marquedimanche;
});
  this.produits=results
     this.dialogdimanche = false

      }

  
   
    },
    togglelundi(){
       
         if(this.showlundi==true)
       {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour lundi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
              this.showlundi=false
          this.events = this.events.filter(x => x.jour !== 'lundi');
      this.lundi.client='';
      this.lundi.date='';
      this.lundi.produit='';
      this.societe="";
      this.marque='';
      this.validelundi=false;
      this.produits=this.produitsoriginal;
          }
        })
       
       }
       else{
         this.showlundi=true
           this.events = this.events.filter(x => x.jour !== 'lundi');
      this.lundi.client='';
      this.lundi.date='';
      this.lundi.produit='';
         this.societe="";
      this.marque='';
      this.validelundi=false;
      this.produits=this.produitsoriginal;
       }
     
      
    },
      togglemardi(){
     
       if(this.showmardi==true)
            {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour mardi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
                 this.showmardi=false
            this.events = this.events.filter(x => x.jour !== 'mardi');
      this.mardi.client='';
      this.mardi.date='';
      this.mardi.produit='';
      this.validemardi=false;
      this.produits=this.produitsoriginal;
          }
        })
       
       }
     
       else{
         this.showmardi=true
         this.events = this.events.filter(x => x.jour !== 'mardi');
      this.mardi.client='';
      this.mardi.date='';
      this.mardi.produit='';
      this.validemardi=false;
      this.produits=this.produitsoriginal;
       }
    },
      togglemercredi(){
     
       if(this.showmercredi==true)
           {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour mercredi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
          this.showmercredi=false
       this.events = this.events.filter(x => x.jour !== 'mercredi');
      this.mercredi.client='';
      this.mercredi.date='';
      this.mercredi.produit='';
      this.validemercredi=false;
      this.produits=this.produitsoriginal;
          }
        })
       
       }
       
     
       else{
         this.showmercredi=true
                this.events = this.events.filter(x => x.jour !== 'mercredi');
      this.mercredi.client='';
      this.mercredi.date='';
      this.mercredi.produit='';
      this.validemercredi=false;
      this.produits=this.produitsoriginal;
       }
    },
      togglejeudi(){
     
       if(this.showjeudi==true)
            {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour jeudi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
  this.showjeudi=false
                  this.events = this.events.filter(x => x.jour !== 'jeudi');
      this.jeudi.client='';
      this.jeudi.date='';
      this.jeudi.produit='';
      this.validejeudi=false;
      this.produits=this.produitsoriginal;
          }
        })
       
       }
      
       else{
         this.showjeudi=true
                this.events = this.events.filter(x => x.jour !== 'jeudi');
      this.jeudi.client='';
      this.jeudi.date='';
      this.jeudi.produit='';
      this.validejeudi=false;
      this.produits=this.produitsoriginal;
       }
    },
      togglevendredi(){
     
       if(this.showvendredi==true)
             {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour vendredi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.showvendredi=false
           this.events = this.events.filter(x => x.jour !== 'vendredi');
      this.vendredi.client='';
      this.vendredi.date='';
      this.vendredi.produit='';
      this.validevendredi=false;
      this.produits=this.produitsoriginal;
 
          }
        })
       
       }
     
       else{
         this.showvendredi=true
      this.events = this.events.filter(x => x.jour !== 'vendredi');
      this.vendredi.client='';
      this.vendredi.date='';
      this.vendredi.produit='';
      this.validevendredi=false;
      this.produits=this.produitsoriginal;
       }
    },
      togglesamdi(){
     
       if(this.showsamdi==true)
                {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour samdi",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
         this.showsamdi=false
           this.events = this.events.filter(x => x.jour !== 'samdi');
      this.samdi.client='';
      this.samdi.date='';
      this.samdi.produit='';
      this.validesamdi=false;
      this.produits=this.produitsoriginal;
 
          }
        })
       
       }
     
       else{
         this.showsamdi=true
      this.events = this.events.filter(x => x.jour !== 'samdi');
      this.samdi.client='';
      this.samdi.date='';
      this.samdi.produit='';
      this.validesamdi=false;
      this.produits=this.produitsoriginal;
       }
    },
      toggledimanche(){
     
       if(this.showdimanche==true)
             {
            Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Annuler le jour dimanche",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
       this.showdimanche=false
                   this.events = this.events.filter(x => x.jour !== 'dimanche');
      this.dimanche.client='';
      this.dimanche.date='';
      this.dimanche.produit='';
      this.validedimanche=false;
      this.produits=this.produitsoriginal;
 
          }
        })
       
       }
      
       else{
         this.showdimanche=true
                this.events = this.events.filter(x => x.jour !== 'dimanche');
      this.dimanche.client='';
      this.dimanche.date='';
      this.dimanche.produit='';
      this.validedimanche=false;
      this.produits=this.produitsoriginal;
       }
    }





        
} 
    
  };
</script>

  <style>
  
  </style>